'use client';

import { Cursor } from '@/components/Common/Loop/Cursor';
import { ArrowButton } from '@/components/Links/ArrowButton';
import { ArrowWithPlume } from '@/components/Shapes';
import { AnimationBlock } from '@/components/AnimationBlock';
import { useRef } from 'react';

interface IProps {
  id: string;
  withLens?: boolean;
  withArrow?: boolean;
  animateEntireBlock?: boolean;
  imageClassName?: string;
  onButtonClick: () => void;
}

export const Loop = ({ withLens, withArrow, animateEntireBlock, imageClassName, onButtonClick, id }: IProps) => {
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const blockRef = useRef(null);

  const titleContent = (
    <h2
      className="
              mobile:text-[45px] mobile:leading-[67.2px] text-white mb-[20px] lg:mb-[42px]
              [&>span]:font-monoItalic [&>span]:text-purple-6
              xl-1:mb-[42px] tracking-[-0.96px] text-3xl leading-[42.2px]
            "
      ref={titleRef}
    >
      We offer a free, <span className="whitespace-nowrap">no-obligation</span> audit of your current software
    </h2>
  );

  const paragraphContent = (
    <p
      className="lg:text-[36px] max-w-[82%] mb-[32px] xl-1:mb-[50px]"
      ref={paragraphRef}
    >
      And we show you how you can make it better
    </p>
  );

  const loopContent = (
    <div
      className="
        container mx-auto flex flex-col items-center xl-1:pr-0
        xl-1:flex-row xl-1:gap-[42px] gap-[20px]
        [@media(min-width:1440px)]:pr-0 lg:px-[64px] xl-plus:px-[32px]
        relative z-10
      "
      ref={blockRef}
    >
      <div className="text-white xl-1:max-w-[50%] z-[10] self-start">
        {animateEntireBlock ? (
          titleContent
        ) : (
          <AnimationBlock
            id={`title-anim-${id}`}
            blockRef={blockRef}
          >
            {titleContent}
          </AnimationBlock>
        )}
        {animateEntireBlock ? (
          paragraphContent
        ) : (
          <AnimationBlock
            id={`title-paragaph-${id}`}
            blockRef={paragraphRef}
          >
            {paragraphContent}
          </AnimationBlock>
        )}

        <ArrowButton
          buttonClassname={`
            text-white hidden xl-1:flex mt-[32px] xl-1:mt-0
            mobile:text-[16px] mobile:leading-[22px]
            mobile:w-[310px] mobile:h-[48px] border-white50
          `}
          buttonTextClassname="relative z-[1] leading-base flex items-center gap-[4px] group"
          gradient="bg-conic-gradient-green"
          gradientPosition="h-[1500%]"
          arrowVariant="secondary"
          type="button"
          onClick={onButtonClick}
        >
          Book a free system audit
        </ArrowButton>
      </div>
      {withArrow && (
        <ArrowWithPlume
          arrowName="arrow-with-plume-pink"
          classname={`h-[174.7px] w-[43.43px] mobile:h-[238.37px] mobile:w-[59.27px]
            top-[15%] left-[68%] mobile:top-[10%] mobile:left-[73%]
            lg:top-[30%] lg:left-[83%] xl-1:top-[-24%] xl-1:left-[83%]
          `}
        />
      )}
      <Cursor
        imageClassName={imageClassName}
        backgroundImage="/images/main/code-background.webp"
        foregroundImage="/images/main/code-dashboard.webp"
      />
      <ArrowButton
        buttonClassname={`
          text-white block xl-1:hidden mt-[32px] xl-1:mt-0 
          mobile:text-[16px] mobile:leading-[22px]
          mobile:w-[310px] mobile:h-[48px] border-white50
          border-white50
        `}
        buttonTextClassname="relative z-[1] leading-base flex items-center gap-[4px] group"
        gradient="bg-conic-gradient-green"
        gradientPosition="h-[1500%]"
        arrowVariant="secondary"
        type="button"
        onClick={onButtonClick}
      >
        Book a free system audit
      </ArrowButton>
    </div>
  );

  return (
    <section className="bg-black-11 relative pb-[80px] lg:pt-[80px] overflow-x-clip">
      {withLens && (
        <div
          className="
            bg-[url(/backgrounds/loupe-lens.svg)] bg-center bg-no-repeat bg-contain
            absolute top-[-50%] right-0 w-[1011px] h-[990px]
            pointer-events-none
          "
        />
      )}
      {animateEntireBlock ? (
        <AnimationBlock
          id={`content-anim-${id}`}
          blockRef={blockRef}
        >
          {loopContent}
        </AnimationBlock>
      ) : (
        loopContent
      )}
    </section>
  );
};
